import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import _ from 'lodash/fp';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NewLanding/components/newNavbar';
import { Icon } from '@assets';
import Select from 'react-select';
import {
  TRIAL_PLANS,
  VISIBLE_CITIES,
  CREATE_SUBSCRIPTION_WITH_SIGNUP,
} from '@api';
import { useQuery, useMutation } from '@apollo/client';
import DivisionTitle, {
  DivisionSubTitle,
} from '../NewLanding/components/divisionTitle';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import config from '@config';
import queryString from 'query-string';

function CardSection({ coupon }) {
  const CARD_ELEMENT_OPTIONS = {
    class: 'form-control',
    style: {
      base: {
        color: '#495057',
        fontSize: '15px',
        // fontFamily: "Nunito Light",
        verticalAlign: 'middle',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: 'grey',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
    hidePostalCode: true,
  };

  return (
    <>
      <Row>
        <Col lg='12' className='mb-3'>
          <CardNumberElement
            options={CARD_ELEMENT_OPTIONS}
            className='form-control d-flex align-items-center'
          />
        </Col>
        <Col xs='4'>
          <CardExpiryElement
            options={CARD_ELEMENT_OPTIONS}
            className='form-control d-flex align-items-center'
          />
        </Col>
        <Col xs='4'>
          <CardCvcElement
            options={CARD_ELEMENT_OPTIONS}
            className='form-control d-flex align-items-center'
          />
        </Col>
        {/* <CardElement options={CARD_ELEMENT_OPTIONS} className="form-control-stripe form-control" /> */}
        {/* <FormGroup className='text-left'> */}
        <Col xs='4'>{coupon}</Col>
      </Row>
    </>
  );
}

function Registration(props) {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const [progress, setProgress] = useState(false);
  const [selectedCities, setSelectedCities] = useState(null);
  const [maxCities, setMaxCities] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planMessage, setPlanMessage] = useState(null);
  const [error, setError] = useState(null);
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [phoneNo, setPhoneNo] = useState();
  const [phoneNoError, setPhoneNoError] = useState(false);
  const [licenseCheck, setLicenseCheck] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState(
    queryString.parse(props.location.search).code
      ? queryString.parse(props.location.search).code
      : ''
  );

  let defaultPlan = queryString.parse(props.location.search).plan
    ? queryString.parse(props.location.search).plan.split('-')
    : null;

  const { data: plans } = useQuery(TRIAL_PLANS, {
    onCompleted: (data) => {
      if (defaultPlan) {
        var selectedPlan = data.trialPlans.filter((plan) => {
          return (
            plan.name.includes(defaultPlan[0]) &&
            defaultPlan[1].includes(plan.interval)
          );
        });
        setSelectedPlan(
          JSON.parse(
            `{"value":${selectedPlan[0].id}, "label":"${selectedPlan[0].name} ${
              selectedPlan[0].interval === 'year' ? 'Yearly' : 'Monthly'
            } ($${
              selectedPlan[0].interval === 'year'
                ? selectedPlan[0].amount / 12
                : selectedPlan[0].amount
            }/mo)"}`
          )
        );
      }
      window.scrollTo(0, 0);
    },
  });

  const { data: cities } = useQuery(VISIBLE_CITIES);
  const [createSubscriptionWithSignup] = useMutation(
    CREATE_SUBSCRIPTION_WITH_SIGNUP,
    {
      onCompleted: (data) => {
        setProgress(false);
        if (
          data.createSubscriptionWithSignup.errors &&
          data.createSubscriptionWithSignup.errors.length > 0
        ) {
          data.createSubscriptionWithSignup.errors.map((err) =>
            toast(err, { type: 'error' })
          );
          if (data.createSubscriptionWithSignup.message === 'Invalid coupon') {
            setCouponError(data.createSubscriptionWithSignup.message);
          }
        } else {
          toast(data.createSubscriptionWithSignup.message, { type: 'success' });
          history.push({
            pathname: '/login',
          });
        }
      },
      onError: (e) => {
        console.log(e, 'error');
      },
    }
  );

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const handleForm = async (data, e) => {
    if (data.phoneNumber === '(555) 555 - 5555') {
      setPhoneNoError(true);
      toast('Are you trying to trick us? We need a real number.', {
        type: 'error',
      });
      return;
    }
    console.log(e, 'Event');
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    setProgress(true);
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setError(result.error.message);
      setProgress(false);
    } else {
      setError('');
      mutationCall(result.token.id, data);
    }
  };

  const mutationCall = (token, data) => {
    createSubscriptionWithSignup({
      variables: {
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNo: data.phoneNumber,
        licenseNo: data.licenseNo,
        stripeToken: token,
        planId: Number(selectedPlan.value),
        coupon: coupon,
        affiliateCode: data.affiliateCode,
        cityIds:
          maxCities === -1
            ? cities.visibleCities.map((city) => Number(city.id))
            : selectedCities.map((city) => Number(city.value)),
      },
    });
  };

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const [confirmPassword, setConfirmPassword] = useState({
    confirm_password: '',
    showConfirmPassword: false,
  });
  
  const handleClickShowConfirmPassword = () => {
    setConfirmPassword({ ...confirmPassword, showConfirmPassword: !confirmPassword.showConfirmPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword({ ...confirmPassword, confirm_password: event.target.value });
  };
  
  useEffect(() => {
    if (selectedPlan) {
      const planDetails = plans.trialPlans.find(
        (plan) => Number(plan.id) === Number(selectedPlan.value)
      );
      setMaxCities(planDetails.maxCities);
      const cityLabel =
        planDetails.maxCities === 1
          ? 'a market'
          : `any ${planDetails.maxCities} markets`;
      const cityLine =
        planDetails.maxCities === -1
          ? 'You will get access to all cities!'
          : `Now, select ${cityLabel} that you want access to.`;
      setPlanMessage(
        <div className='text-left info-panel'>
          Awesome! you have chosen <strong>{planDetails.name}</strong>{' '}
          {planDetails.interval === 'year' ? 'yearly' : 'monthly'} plan.
          <br />
          You will be charged{' '}
          {planDetails.interval === 'year'
            ? `$${planDetails.amount}/year.`
            : `$${planDetails.amount}/month.`}
          <br />
          {cityLine}
        </div>
      );
    } else {
      setPlanMessage(null);
    }
  }, [selectedPlan]);

  const isLogin = () => {
    if (localStorage.getItem('auth_token')) {
      history.push({
        pathname: '/login',
      });
    }
  };

  const isNumericInput = (event) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  const enforceFormat = (event) => {
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };

  const formatToPhone = (event) => {
    if (isModifierKey(event)) {
      return;
    }

    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      event.target.value = `(${areaCode}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      event.target.value = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `(${areaCode}`;
    }
  };

  const inputElement = document.getElementById('phoneNumber');
  if (inputElement != null) {
    inputElement.addEventListener('keydown', enforceFormat);
    inputElement.addEventListener('keyup', formatToPhone);
  }

  useEffect(() => {
    isLogin();
    return () => {};
  }, []);

  return (
    <>
      <NavBar />
      {/* <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Sign up</h1>
        </Container>
      </Container> */}
      <Container fluid className='py-5em mt-5em text-center'>
        <Container>
          <Row className='px-lg-5 mb-'>
            <Col md='12'>
              <DivisionTitle
                className='mb-4'
                title={
                  <>
                    Let's <span className='highlighted-text'>get started.</span>
                  </>
                }
              />
              {/* <DivisionSubTitle
                className="mb-5"
                title="Getting leases is about to become much easier."
              /> */}
              <p>
                <small>
                  Already have an account? <NavLink to='/login'>Log In</NavLink>
                </small>
              </p>
            </Col>
            <Col md={{ size: 4, offset: 4 }} className='inputFields'>
              <Form className='form' onSubmit={handleSubmit(handleForm)}>
                <FormGroup>
                  <Input
                    type='text'
                    placeholder='First Name'
                    name='firstName'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('firstName.type', errors) === 'required' && (
                    <p className='input-error'>* First Name required.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Last Name'
                    name='lastName'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('lastName.type', errors) === 'required' && (
                    <p className='input-error'>* Last Name required.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    id='phoneNumber'
                    type='text'
                    placeholder='Phone Number'
                    name='phoneNumber'
                    autoComplete='off'
                    // maxlength="16"
                    // onInput={(e.value) = phoneFormat(e.value)}
                    innerRef={register({
                      required: true,
                      minLength: 16,
                      maxLength: 16,
                    })}
                  />
                  {_.get('phoneNumber.type', errors) === 'required' && (
                    <p className='input-error'>* Phone Number required.</p>
                  )}
                  {(_.get('phoneNumber.type', errors) === 'minLength' ||
                    _.get('phoneNumber.type', errors) === 'maxLength') && (
                    <p className='input-error'>* Invalid Phone Number.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Email'
                    name='email'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                  {_.get('email.type', errors) === 'required' && (
                    <p className='input-error'>* Email required.</p>
                  )}
                  {_.get('email.type', errors) === 'pattern' && (
                    <p className='input-error'>* Invalid Email.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='text'
                    placeholder='License Number'
                    name='licenseNo'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('licenseNo.type', errors) === 'required' && (
                    <p className='input-error'>* License required.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        icon='eye'
                      />
                    </InputGroupText>
                    <Input
                      type={values.showPassword ? 'text' : 'password'}
                      onChange={handlePasswordChange('password')}
                      value={values.password || ''}
                      placeholder='Create Password'
                      name='password'
                      autoComplete='off'
                      innerRef={register({
                        required: true,
                        minLength: 6,
                      })}
                    />
                  </InputGroup>
                  {_.get('password.type', errors) === 'required' && values.password?.length === 0 && (
                    <p className='input-error'>* Password required</p>
                  )}
                  {/* {_.get('password.type', errors) === 'minLength' && (
                    <p className='input-error'>
                      * Password should be minimum 6 letters
                    </p>
                  )} */}
                  {values.password && values.password.length < 6 && (
                    <p className='input-error'>
                      * Password should be minimum 6 letters
                    </p>
                  )}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon
                        onClick={handleClickShowConfirmPassword}
                        icon='eye'
                      />
                    </InputGroupText>
                    <Input
                      type={confirmPassword.showConfirmPassword ? 'text' : 'password'}
                      onChange={handleConfirmPasswordChange}
                      value={confirmPassword.confirm_password || ''}
                      placeholder='Confirm Password'
                      name='confirm_password'
                      autoComplete='off'
                      innerRef={register({
                        required: true,
                        minLength: 6,
                      })}
                    />
                  </InputGroup>
                  {_.get('confirm_password.type', errors) === 'required' && confirmPassword.confirm_password?.length === 0 && (
                    <p className='input-error'>* Please confirm your password</p>
                  )}
                  {confirmPassword.confirm_password && confirmPassword.confirm_password.length < 6 && (
                    <p className='input-error'>
                      * Confirm Password should be minimum 6 letters
                    </p>
                  )}
                  {confirmPassword.confirm_password !== values.password && confirmPassword.confirm_password && (
                    <p className='input-error'>* Passwords do not match</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type={
                      queryString.parse(props.location.search).code &&
                      queryString.parse(props.location.search).code !== ''
                        ? 'hidden'
                        : 'text'
                    }
                    //type='text'

                    placeholder='Referral Code'
                    name='affiliateCode'
                    autoComplete='off'
                    value={affiliateCode}
                    onChange={(e) => setAffiliateCode(e.target.value)}
                    innerRef={register({
                      required: false,
                    })}
                  />
                </FormGroup>

                <FormGroup className='text-left'>
                  <Select
                    name='plan'
                    value={selectedPlan}
                    isClearable={true}
                    classNamePrefix='default-select'
                    placeholder={<>Select Plan</>}
                    options={
                      typeof plans !== 'undefined'
                        ? Object.values(plans.trialPlans).map(
                            (plan) =>
                              JSON.parse(
                                `{"value":${plan.id}, "label":"${plan.name} ${
                                  plan.interval === 'year'
                                    ? 'Yearly'
                                    : 'Monthly'
                                } ($${
                                  plan.interval === 'year'
                                    ? plan.amount / 12
                                    : plan.amount
                                }/mo)"}`
                              )
                            // - ${
                            //   plan.maxCities === -1
                            //     ? 'All City Access'
                            //     : plan.maxCities === 1
                            //     ? '1 City'
                            //     : `${plan.maxCities} Cities`
                            // }
                          )
                        : []
                    }
                    onChange={(plan) => {
                      setSelectedPlan(plan);
                      setSelectedCities(null);
                    }}
                    // isSearchable={true}
                    className='w-100'
                  />
                  {_.get('plan.type', errors) === 'required' && (
                    <p className='input-error'>* Please select a plan</p>
                  )}
                </FormGroup>

                {selectedPlan && (
                  <>
                    <FormGroup className=''>{planMessage}</FormGroup>
                    {maxCities > 0 && (
                      <>
                        <FormGroup className='text-left'>
                          <Select
                            value={
                              maxCities === 1 && selectedCities
                                ? selectedCities[0]
                                : selectedCities
                            }
                            name='city'
                            classNamePrefix='default-select'
                            isMulti={maxCities !== 1}
                            isClearable={maxCities > 1}
                            placeholder='Select Market'
                            options={
                              typeof cities !== 'undefined'
                                ? Object.values(cities.visibleCities).map(
                                    (city) =>
                                      JSON.parse(
                                        `{"value":${city.id}, "label":"${city.name}"}`
                                      )
                                  )
                                : []
                            }
                            onChange={(selectedCity) => {
                              maxCities === 1
                                ? setSelectedCities([selectedCity])
                                : selectedCity.length <= maxCities ||
                                  maxCities === -1
                                ? setSelectedCities(selectedCity)
                                : null;
                            }}
                            className='w-100'
                          />
                        </FormGroup>
                      </>
                    )}
                  </>
                )}

                {selectedPlan && (
                  <>
                    <FormGroup className='text-left'>
                      {/* <label> Payment Information</label> */}
                      <CardSection
                        coupon={
                          <>
                            <Input
                              type='text'
                              value={coupon}
                              onChange={(e) => setCoupon(e.target.value)}
                              name='coupon'
                              placeholder='Coupon'
                            />
                            {couponError && (
                              <p className='input-error'>{couponError} </p>
                            )}
                          </>
                        }
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup check className='text-left mb-3'>
                  <Input type='checkbox' checked={true} disabled={true} />
                  <Label check className='terms-check'>
                    Two-factor authentication enabled, so make sure you have
                    entered correct email and phone number.
                  </Label>
                </FormGroup>
                <FormGroup check className='text-left mb-3'>
                  <Input
                    type='checkbox'
                    checked={licenseCheck}
                    value={licenseCheck}
                    onChange={() => {
                      setLicenseCheck(!licenseCheck);
                    }}
                  />
                  <Label
                    check
                    className='terms-check'
                    onClick={() => {
                      setLicenseCheck(!licenseCheck);
                    }}
                  >
                    I am a licensed agent and am using information from Spark
                    for real estate services. I understand if my license number
                    is found to be incorrect or expired, my account will be
                    cancelled immediately.
                  </Label>
                </FormGroup>
                <FormGroup check className='text-left mb-3'>
                  <Input
                    type='checkbox'
                    checked={termsCheck}
                    value={termsCheck}
                    onChange={() => {
                      setTermsCheck(!termsCheck);
                    }}
                  />
                  <Label
                    check
                    className='terms-check'
                    onClick={() => {
                      setTermsCheck(!termsCheck);
                    }}
                  >
                    I have read and agreed to{' '}
                    <NavLink
                      to='/terms-of-service'
                      className='highlighted-text'
                    >
                      Terms
                    </NavLink>
                    .
                  </Label>
                </FormGroup>

                <Button
                  color='green'
                  size='lg'
                  disabled={progress || !termsCheck || !licenseCheck}
                  className='btn-rounded'
                >
                  {progress ? 'Progress' : 'Get Started'}
                </Button>
              </Form>
            </Col>
          </Row>

          <Row className='pt-5 privacy-policy'>
            <Col md={{ size: 4, offset: 4 }}>
              <p className='mb-0'>
                <small>By Continuing you agree to accept our</small>
              </p>
              <p className='mb-5'>
                <small>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink> &{' '}
                  <NavLink to='/terms-of-service'>Terms of Service.</NavLink>
                </small>
              </p>
              <hr className='border-light' />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default function RegistrationForm(props) {
  const stripePromise = loadStripe(config.url.REACT_APP_STRIPE_KEY);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Registration stripe={stripe} elements={elements} {...props} />
        )}
      </ElementsConsumer>
    </Elements>
  );
}
