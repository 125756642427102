import React, {useState, useEffect} from 'react';
import config from '@config';
import { 
  MY_PROFILE, 
  GET_REFERRAL_USER } from '@api';
import Spinner from '../Spinner';
import { useQuery } from "@apollo/client";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Table,
} from 'reactstrap';

export default function ReferFriend() {
  const [user, setUser] = useState([]);

  const {
    data: profileData,
    loading: ProfileLoading,
  } = useQuery(MY_PROFILE, {
    onCompleted: (data) => {
      if (data.myProfile) {
        // setuser(data.myProfile);
      } else {
        toast('Profile not found', { type: 'error' });
      }
    },
    onError: (error) => {
      console.log(error + '>>>>> ERROR >>>>>');
      toast('Profile not found', { type: 'error' });
    },
  });

  useEffect(() => {
    if (profileData) {
			setUser(profileData.myProfile);
		}
  }, [profileData]);

  const { data: referralData } = useQuery(GET_REFERRAL_USER);
  
  return (
    <>
      <Container className='py-5 text-center'>
      {ProfileLoading && <Spinner />}
      <Row>
        <Col md='12'>
          <h2>Receive one week free!</h2>
          <h4>Refer a friend using the link below.</h4>
          <h4>After they're subscribed for 30 days, 1 week free will be added to your account.</h4>
          <InputGroup className="justify-content-center pt-4">
            <span style={{ paddingTop: '7px', fontSize: "medium"}}> {config.url.WEB_DOMAIN}signup?code={user.slug}</span>
          </InputGroup>
          <Button color='primary' className='button-dashboard mt-5' onClick={() => navigator.clipboard.writeText(`${config.url.WEB_DOMAIN}signup?code=${user.slug}`)}>
            Copy Referral Link
          </Button>
          <h5 className="mt-3">My Referrals: {referralData?.findReferralUsers?.totalCount}</h5>
          {referralData?.findReferralUsers?.totalCount > 0 &&
            <Table responsive className="client-list mt-2 ml-5" style={{ width: '90%' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {referralData?.findReferralUsers?.edges?.map((referral) => (
                  <tr>
                    <td>{referral.node?.name}</td>
                    <td>{referral.node?.email}</td>
                    <td>{referral.node?.phoneNo}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </Col>
      </Row>
      </Container>
    </>
  )
}
